import type { IStatusPanelParams } from 'ag-grid-community';

import { useGrid } from './context';

const numberFormatter = new Intl.NumberFormat('en-US');

export function TotalStatusPanel(_props: IStatusPanelParams) {
  const [store] = useGrid();
  return typeof store.total === 'number' ? (
    <div className="ag-status-name-value">
      <span>Total:&nbsp;</span>
      <span className="ag-status-name-value-value">
        {typeof store.total === 'number'
          ? numberFormatter.format(store.total)
          : null}
      </span>
    </div>
  ) : (
    <div />
  );
}

export function LoadedStatusPanel(_props: IStatusPanelParams) {
  const [store] = useGrid();
  return typeof store.loaded === 'number' ? (
    <div className="ag-status-name-value">
      <span>Loaded:&nbsp;</span>
      <span className="ag-status-name-value-value">
        {' '}
        {typeof store.loaded === 'number'
          ? numberFormatter.format(store.loaded)
          : null}
      </span>
    </div>
  ) : (
    <div />
  );
}
