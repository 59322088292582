import type { ICellRendererParams } from 'ag-grid-community';
import { PrimeIcons } from 'primereact/api';
import { memo } from 'react';

export const BooleanRenderer = memo(function BooleanRenderer<T>(
  props: ICellRendererParams<T, boolean>,
) {
  if (props.value === true) {
    return (
      <div style={{ display: 'grid', justifyContent: 'center', width: '100%' }}>
        <i className={PrimeIcons.CHECK} style={{ color: 'green' }} />
      </div>
    );
  }

  return null;
});

export const ErrorRenderer = memo(function ErrorRenderer<T>(
  props: ICellRendererParams<T, boolean>,
) {
  if (props.value === true) {
    return <i className={PrimeIcons.EXCLAMATION_TRIANGLE} />;
  }

  return null;
});

export const NumberRenderer = memo(function NumberRenderer<T>(
  props: ICellRendererParams<T, number | null>,
) {
  if (typeof props.value === 'number' && props.value !== 0) {
    return <span>{props.value}</span>;
  }

  return null;
});
