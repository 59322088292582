import type {
  FilterChangedEvent,
  GridReadyEvent,
  RowDoubleClickedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import type { GetRowIdFunc, GridOptions } from 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { applyGridState, encodeGridState, SortModel } from './grid-utils';
import {
  useColumnDefStringMaps,
  useExportParams,
  useKeyboardNavigation,
  useRefreshGrid,
} from './hooks';
import type { ColDefs } from './types';

LicenseManager.setLicenseKey(
  'CompanyName=Transova Genetics,LicensedGroup=TogItAgGrid,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-036113,SupportServicesEnd=21_December_2023_[v2]_MTcwMzExNjgwMDAwMA==37fa922df2c20fe993849bb3f83d7069',
);

const GRID_STATE_PARAM = 'grid';

type GridProps<T extends object> = Omit<
  AgGridReactProps<T>,
  | 'columnDefs'
  | 'defaultCsvExportParams'
  | 'defaultExcelExportParams'
  | 'navigateToNextCell'
  | 'onCellKeyDown'
  | 'onFilterChanged'
  | 'onRowDoubleClicked'
  | 'onSortChanged'
  | 'ref'
  | 'rowSelection'
  | 'suppressCsvExport'
  | 'suppressExcelExport'
  | 'tooltipShowDelay'
> & {
  // ref?: RefObject<AgGridReact<T>>;
  fileName?: string;
  columnDefs: ColDefs<T>;
  gridOptions?: GridOptions<T>;
  class?: string;
  onSelect?: (row: T) => void;
  rememberGridState?: boolean;
  showCheckboxes?: boolean;
};

const gridDefaultColDef = {
  initialWidth: 160,
  sortable: true,
  resizable: true,
};
const getRowId: GetRowIdFunc<any> = (item) => {
  return item.data?.id;
};

function InnerGrid<T extends object>(props: GridProps<T>) {
  const grid = useRef<AgGridReact>(null);
  useRefreshGrid(grid, props.rowModelType ?? 'clientSide');

  const defaultExportParams = useExportParams(props.fileName);

  const columnDefs = useColumnDefStringMaps(
    props.columnDefs,
    props.showCheckboxes ?? false,
  );

  const onSelect = props.onSelect;
  const { handleKeyDown, navigateToNextCell } = useKeyboardNavigation(onSelect);

  const handleDoubleClick = useCallback(
    ({ data, node }: RowDoubleClickedEvent<T>) => {
      if (data && onSelect && node.selectable) {
        onSelect(data);
      }
    },
    [onSelect],
  );

  const [params, setParams] = useSearchParams();

  const defaultOnGridReady = props.onGridReady;
  const rememberGridState = props.rememberGridState;
  const handleOnGridReady = useCallback(
    (e: GridReadyEvent<T>) => {
      if (rememberGridState) {
        const encodedGridState = params.get(GRID_STATE_PARAM);
        applyGridState(e.api, e.columnApi, encodedGridState);
      }

      if (defaultOnGridReady) {
        defaultOnGridReady(e);
      }
    },
    [rememberGridState, defaultOnGridReady, params],
  );

  useEffect(() => {
    // sync filter model when params change due to browser navigation
    // changing filters adds a new item to the browser history
    // going forward/backward across filter changes does not call onGridReady

    if (!grid.current || !rememberGridState) {
      return;
    }

    const columns = grid.current.columnApi?.getColumns();
    if (!columns) {
      return;
    }
    const cols = columns?.map((c) => c.getColDef().field) as
      | string[]
      | undefined;
    if (!cols) {
      return;
    }

    const sortModel = Object.fromEntries(
      columns
        .map((c) => [c.getColId(), c.getSort()] as const)
        .filter(([name, value]) => name && value),
    ) as SortModel;

    const newParams = new URLSearchParams(params);
    const encodedGridState = encodeGridState(
      cols,
      grid.current.api.getFilterModel(),
      sortModel,
    );
    if (encodedGridState) {
      newParams.set(GRID_STATE_PARAM, encodedGridState);
    } else {
      newParams.delete(GRID_STATE_PARAM);
    }

    const paramsChanged =
      newParams.get(GRID_STATE_PARAM) !== params.get(GRID_STATE_PARAM);

    if (paramsChanged) {
      applyGridState(
        grid.current.api,
        grid.current.columnApi,
        params.get(GRID_STATE_PARAM),
      );
    }
  }, [params, rememberGridState]);

  const handleFilterOrSortChanged = useCallback(
    ({ api, columnApi }: FilterChangedEvent<T> | SortChangedEvent<T>) => {
      // onFilterChanged gets called after onGridReady updates filter model and
      // when the filter model changes from user interaction

      if (!rememberGridState) {
        return;
      }

      const columns = columnApi?.getColumns();
      if (!columns) {
        return;
      }
      const cols = columns
        .map((c) => c.getColDef().field)
        .filter((f) => f) as string[];

      const filterModel = api.getFilterModel();
      const sortModel = Object.fromEntries(
        columns
          .map((c) => [c.getColId(), c.getSort()] as const)
          .filter(([name, value]) => name && value),
      ) as SortModel;

      const newParams = new URLSearchParams(params);
      const encodedGridState = encodeGridState(cols, filterModel, sortModel);
      if (encodedGridState) {
        newParams.set(GRID_STATE_PARAM, encodedGridState);
      } else {
        newParams.delete(GRID_STATE_PARAM);
      }

      const paramsChanged =
        newParams.get(GRID_STATE_PARAM) !== params.get(GRID_STATE_PARAM);
      // prevent interrupting the browser history when filters haven't actually changed
      if (paramsChanged) {
        // adds a new item to the browser history stack
        setParams(newParams);
      }
    },
    [params, rememberGridState, setParams],
  );

  const floatingFilter = columnDefs.some((col) => col.filter);
  const defaultColDef = useMemo(
    () => ({ floatingFilter, ...gridDefaultColDef, ...props.defaultColDef }),
    [floatingFilter, props.defaultColDef],
  );

  return (
    <AgGridReact
      className="ag-theme-balham"
      getRowId={getRowId}
      {...props}
      tooltipShowDelay={300}
      defaultColDef={defaultColDef}
      onGridReady={handleOnGridReady}
      onFilterChanged={handleFilterOrSortChanged}
      onSortChanged={handleFilterOrSortChanged}
      columnDefs={columnDefs}
      ref={grid}
      defaultCsvExportParams={defaultExportParams}
      defaultExcelExportParams={defaultExportParams}
      suppressCsvExport={!props.fileName}
      suppressExcelExport={!props.fileName}
      rowSelection={props.showCheckboxes ? 'multiple' : 'single'}
      onRowDoubleClicked={handleDoubleClick}
      // disabled keyboard navigation when using checkboxes
      navigateToNextCell={props.showCheckboxes ? undefined : navigateToNextCell}
      onCellKeyDown={props.showCheckboxes ? undefined : handleKeyDown}
    />
  );
}
export const Grid = memo(InnerGrid) as typeof InnerGrid;
