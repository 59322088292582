import { gql, useApolloClient } from '@apollo/client';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community';
import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import capitalize from 'lodash/capitalize';
import orderBy from 'lodash/orderBy';
import set from 'lodash/set';
import upperCase from 'lodash/upperCase';
import { useMemo } from 'react';
import { toast } from 'react-toastify';

import type { Query } from '~/types.gql';

import { useGrid } from './context';
import type { FilterModel, SupportedFilter } from './grid-utils';
import type { ResourceColDefs } from './types';

function makeFieldFilter(value: SupportedFilter) {
  if (value.filterType === 'set') {
    return {
      in: value.values.map((v) => {
        if (v === null) {
          return v;
        }

        const n = parseInt(v, 10);
        if (Number.isInteger(n)) {
          return n;
        }

        return v;
      }),
    };
  }
  if (value.filterType === 'text') {
    if (value.type === 'contains') {
      return { contains: value.filter };
    }
    if (value.type === 'notContains') {
      return { ncontains: value.filter };
    }
    if (value.type === 'equals') {
      return { eq: value.filter };
    }
    if (value.type === 'notEqual') {
      return { neq: value.filter };
    }
    if (value.type === 'endsWith') {
      return { endsWith: value.filter };
    }
    if (value.type === 'startsWith') {
      return { startsWith: value.filter };
    }
    if (value.type === 'greaterThan') {
      return { startsWith: value.filter };
    }
    if (value.type === 'blank') {
      return { eq: '' };
    }
    if (value.type === 'notBlank') {
      return { neq: '' };
    }
  }

  console.warn(`Filter method ${value.filterType} not supported`);
  toast.warn(`Filter method ${value.filterType} not supported`);

  return;
}

function whereFromFilterModel(request: IServerSideGetRowsRequest) {
  const where: Record<string, ReturnType<typeof makeFieldFilter>> = {};
  for (const [fieldName, filterModel] of orderBy(
    Object.entries(request.filterModel as FilterModel),
    ([key]) => key.split('.').length,
    'desc',
  )) {
    if ('operator' in filterModel) {
      if (!filterModel.conditions) {
        continue;
      }

      const fieldFilters = filterModel.conditions
        .map((c) => makeFieldFilter(c))
        .filter((f) => !!f);

      const operator = filterModel.operator === 'AND' ? 'and' : 'or';

      if (fieldFilters.length) {
        set(where, `${fieldName}.${operator}`, fieldFilters);
      }

      continue;
    }

    const fieldFilter = makeFieldFilter(filterModel);
    if (fieldFilter) {
      set(where, fieldName, fieldFilter);
    }
  }

  return where;
}

type BaseResult<T> = {
  __typename: string;
  totalCount: number;
  items: Array<T> | null;
} | null;
export function useApolloDatasource<
  Result,
  Variables extends {
    skip: number | null;
    take: number | null;
    where: object | null;
  },
>(
  query: TypedDocumentNode<Result, Variables>,
  queryName: keyof Result,
  vars?: Omit<Variables, 'skip' | 'take' | 'order'> & {
    skip?: never;
    take?: never;
    order?: never;
  },
) {
  const client = useApolloClient();

  const [_, setStore] = useGrid();

  const variables = useMemo(
    () => vars,
    // if variables are defined, they're always a fresh object, check values instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
    vars ? Object.values(vars) : [],
  );

  const source: IServerSideDatasource = useMemo(
    () => ({
      async getRows({
        request,
        success,
        fail,
        columnApi,
      }: IServerSideGetRowsParams) {
        if (
          typeof request.startRow === 'undefined' ||
          typeof request.endRow === 'undefined'
        ) {
          return;
        }

        const order = request.sortModel
          .map((s) => ({
            sort: s.sort,
            field: columnApi.getColumn(s.colId)?.getColDef().field,
          }))
          .filter((s) => s.field)
          .map((s) => set({}, s.field!, s.sort === 'asc' ? 'ASC' : 'DESC'));

        const where = whereFromFilterModel(request);

        try {
          const res = await client.query({
            query,
            variables: {
              ...(variables as any),
              skip: request.startRow,
              take: request.endRow - request.startRow,
              order,
              where: (variables as any)?.where
                ? { ...variables?.where, ...where }
                : undefined,
            },
          });

          const result = res.data?.[queryName] as
            | BaseResult<unknown>
            | undefined;
          const items = result?.items;
          if (!items) {
            throw new Error();
          }

          setStore({
            total: result.totalCount,
            loaded: request.startRow + items.length,
          });
          success({ rowData: items, rowCount: result.totalCount });
        } catch (e) {
          fail();
          setStore({
            total: undefined,
            loaded: undefined,
          });
        }
      },
    }),
    [client, query, queryName, setStore, variables],
  );

  return source;
}

function singular(s: string) {
  return s.endsWith('s') ? s.slice(0, s.length - 1) : s;
}

export function useResource<
  T extends object,
  TItem extends object,
  TFilter extends Record<string, any>,
>(
  resource: keyof Query | { query: keyof Query; entity: string },
  columns: ResourceColDefs<T, TItem>,
  pipes?: {
    filter?: (where: TFilter) => TFilter;
    item?: (item: T) => TItem;
  },
) {
  const client = useApolloClient();

  const [_, setStore] = useGrid();

  const queryName = typeof resource === 'string' ? resource : resource.query;

  const singularResource =
    typeof resource === 'string' ? singular(resource) : resource.query;
  const typeName =
    typeof resource === 'string'
      ? capitalize(singularResource)
      : resource.entity;

  const source: IServerSideDatasource = useMemo(
    () => ({
      async getRows({
        request,
        success,
        fail,
        columnApi,
      }: IServerSideGetRowsParams) {
        if (
          typeof request.startRow === 'undefined' ||
          typeof request.endRow === 'undefined'
        ) {
          return;
        }

        const order = request.sortModel
          .map((s) => ({
            sort: s.sort,
            field: columnApi.getColumn(s.colId)?.getColDef().field,
          }))
          .filter((s) => s.field)
          .map((s) => set({}, s.field!, s.sort === 'asc' ? 'ASC' : 'DESC'));

        const where = whereFromFilterModel(request) as TFilter;

        try {
          const fields = columns
            .filter((s) => s.field)
            .flatMap((col) =>
              col.relatedFields
                ? (col.relatedFields as string[]).concat(col.field!)
                : [col.field!],
            );
          const queryFields = orderBy(
            fields,
            (field) => field.split('.').length,
            'desc',
          ).reduce((acc, field) => {
            set(acc, field, true);
            return acc;
          }, {});

          const query = jsonToGraphQLQuery({
            query: {
              __name: `${upperCase(typeName).replace(/ /g, '_')}_RESULTS`,
              __variables: {
                skip: 'Int',
                take: 'Int',
                order: `[${typeName}SortInput!]`,
                where: `${typeName}FilterInput`,
              },
              [queryName]: {
                __args: {
                  skip: new VariableType('skip'),
                  take: new VariableType('take'),
                  order: new VariableType('order'),
                  where: new VariableType('where'),
                },
                totalCount: true,
                items: { id: true, ...queryFields },
              },
            },
          });
          const res = await client.query({
            query: gql(query),
            variables: {
              skip: request.startRow,
              take: request.endRow - request.startRow,
              order,
              where: pipes?.filter?.(where) ?? where,
            },
          });

          const result = res.data?.[queryName] as BaseResult<T> | undefined;
          const items = result?.items;
          if (!items) {
            throw new Error();
          }

          setStore({
            total: result.totalCount,
            loaded: request.startRow + items.length,
          });
          success({
            rowData: pipes?.item ? items.map(pipes.item) : items,
            rowCount: result.totalCount,
          });
        } catch (e) {
          console.error(e);

          fail();
          setStore({
            total: undefined,
            loaded: undefined,
          });
        }
      },
    }),
    [client, columns, pipes, queryName, setStore, typeName],
  );

  return source;
}
