import { useQuery } from '@apollo/client';
import type {
  CellKeyDownEvent,
  CsvExportParams,
  ExcelExportParams,
  ISetFilterParams,
  KeyCreatorParams,
  NavigateToNextCellParams,
  RowModelType,
  ValueFormatterParams,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { RefObject, useCallback, useMemo } from 'react';
import { useListener } from 'react-bus';

import type { CellStyle } from '@transova/utilities';

import {
  ME,
  STRING_MAPS,
  StringMapFilterInput,
  StringMapFragment,
} from '~/types.gql';

import type { ColDef, ColDefs } from './types';

export const REFRESH_GRID_EVENT = 'REFRESH_GRID';

export function useRefreshGrid(
  gridRef: RefObject<AgGridReact<any>>,
  rowModelType: RowModelType,
) {
  useListener(
    REFRESH_GRID_EVENT,
    useCallback(() => {
      // purge will show the loading rows
      if (rowModelType === 'serverSide') {
        gridRef.current?.api.refreshServerSide({ purge: true });
      }

      // gridRef is a ref and does not need to be tracked
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowModelType]),
  );
}

export function useExportParams(fileName: string | undefined) {
  const me = useQuery(ME);
  const defaultExportParams = useMemo<ExcelExportParams & CsvExportParams>(
    () => ({
      sheetName: fileName,
      fileName: fileName,
      author: me.data?.me.fullName ?? 'Trans Ova Genetics',
    }),
    [me.data?.me.fullName, fileName],
  );

  return defaultExportParams;
}

export function useKeyboardNavigation<T>(
  onSelect: ((row: T) => void) | undefined,
) {
  const navigateToNextCell = useCallback(
    (params: NavigateToNextCellParams<T>) => {
      const suggestedNextCell = params.nextCellPosition;

      const KEY_UP = 'ArrowUp';
      const KEY_DOWN = 'ArrowDown';

      const upOrDownKey = params.key === KEY_DOWN || params.key === KEY_UP;
      if (upOrDownKey && suggestedNextCell) {
        params.api.forEachNode((node) => {
          if (node.rowIndex === suggestedNextCell.rowIndex) {
            node.setSelected(true);
          }
        });
      }

      return suggestedNextCell;
    },
    [],
  );

  const handleKeyDown = useCallback(
    ({ data, event, node }: CellKeyDownEvent<T>) => {
      if (
        onSelect &&
        data &&
        event instanceof KeyboardEvent &&
        event.key === 'Enter' &&
        node.selectable
      ) {
        onSelect(data);
      }
    },
    [onSelect],
  );

  return { navigateToNextCell, handleKeyDown };
}

export const checkboxColumn = {
  width: 30,
  maxWidth: 30,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  pinned: true,
  cellStyle: { justifyContent: 'center' } satisfies CellStyle<any>,
  suppressMenu: true,
  sortable: false,
} satisfies ColDef<any>;

export function useColumnDefStringMaps<T extends object>(
  columnDefs: ColDefs<T>,
  showCheckboxes: boolean,
) {
  const names = useMemo(
    () =>
      columnDefs
        .map(
          (def) =>
            ('filterStringMap' in def ? def['filterStringMap'] : undefined)!,
        )
        .filter(Boolean)
        .map<StringMapFilterInput>((stringMap) => ({
          objectTypeCode: { eq: stringMap.objectTypeCode },
          attributeName: { eq: stringMap.name },
        })),
    [columnDefs],
  );
  const stringMapsRes = useQuery(
    STRING_MAPS,
    useMemo(
      () => ({
        variables: { where: { or: names } },
        skip: !names.length,
      }),
      [names],
    ),
  );
  const stringMaps = useMemo(() => {
    const maps = stringMapsRes.data?.stringMaps?.items;
    if (!maps) {
      return;
    }
    const grouped = groupBy(
      maps,
      (m) => `${m.objectTypeCode}:${m.attributeName}`,
    );
    return Object.fromEntries(
      Object.entries(grouped).map(([key, values]) => [
        key,
        orderBy(values, 'displayOrder', 'asc'),
      ]),
    );
  }, [stringMapsRes.data?.stringMaps]);

  return useMemo(() => {
    const cols = !stringMaps
      ? columnDefs
      : columnDefs.map<ColDef<any>>((def) => {
          if ('filterStringMap' in def && def.filterStringMap) {
            const values =
              stringMaps[
                `${def.filterStringMap.objectTypeCode}:${def.filterStringMap.name}`
              ];
            return {
              ...def,
              filterParams: {
                values,
                defaultToNothingSelected: true,
                keyCreator({
                  value,
                }: KeyCreatorParams<any, StringMapFragment>) {
                  return value?.attributeValue.toString();
                },
                valueFormatter({
                  value,
                }: ValueFormatterParams<any, StringMapFragment>) {
                  return value?.value;
                },
              } as ISetFilterParams<any, StringMapFragment>,
            };
          }

          return def;
        });

    if (showCheckboxes) {
      return [checkboxColumn as ColDef<any>].concat(cols);
    }
    return cols;
  }, [showCheckboxes, columnDefs, stringMaps]);
}
