import { useApolloClient, useQuery } from '@apollo/client';
import {
  faCircleExclamation,
  faDatabase,
  faFileExcel,
  faFlag,
  faHouse,
  faMessage,
  faMicroscope,
  faTableList,
  faTruck,
  faUserTag,
  faVial,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '@images/logo_transova.svg?react';
import { PrimeIcons } from 'primereact/api';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBus } from 'react-bus';
import { Outlet, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { REFRESH_GRID_EVENT } from '~/components/grid';
import { Icon, RouteDef, Sidemenu } from '~/components/sidemenu';
import { CHECK_FEATURES, ME, MeFragment } from '~/types.gql';

const IT_DEPARTMENT = 'IT Department';

type NucleusRouteDef = RouteDef & {
  isAllowed?: (me: MeFragment) => boolean;
  adGroup?: string;
  featureFlag?: string;
  children?: Record<string, NucleusRouteDef>;
};

const root: RouteDef = {
  name: 'Dashboard',
  icon: () => (
    <FontAwesomeIcon icon={faHouse} style={{ marginRight: 'var(--size-2)' }} />
  ),
};

// define menu and breadcrumb routes here
const routes: Record<string, NucleusRouteDef> = {
  it: {
    name: 'Information Services',
    icon: () => <FontAwesomeIcon icon={faWrench} />,
    adGroup: IT_DEPARTMENT,
    children: {
      '/': { name: 'Homepage' },
      features: {
        name: 'Feature Flags',
        icon: () => <FontAwesomeIcon icon={faFlag} />,
      },
      aeta: {
        name: 'AETA Results',
        icon: () => <FontAwesomeIcon icon={faTableList} />,
      },
    },
  },
  shipping: {
    name: 'Shipping',
    icon: () => <FontAwesomeIcon icon={faTruck} />,
    adGroup: 'TOG Shipping',
    children: {
      shipments: { name: 'All Shipments' },
      ups: { name: 'UPS/FedEx' },
      pickup: { name: 'Customer Pickup/GAC' },
      delivery: { name: 'TOG Delivery' },
      courier: { name: 'Courier' },
      export: { name: 'Export' },
    },
  },
  stripping: {
    name: 'Stripping',
    icon: () => <FontAwesomeIcon icon={faMicroscope} />,
    adGroup: 'Embryologists',
  },
  csr: {
    name: 'CSR',
    icon: () => <FontAwesomeIcon icon={faUserTag} />,
    children: {
      shipments: { name: 'Shipments' },
      'sire-selection': { name: 'Sire Selection' },
    },
    adGroup: 'CSR',
  },
  issues: {
    name: 'Issues',
    icon: () => <FontAwesomeIcon icon={faCircleExclamation} />,
    children: {
      mine: {
        name: 'My Issues',
        children: {
          '/': { name: 'My Issues' },
          open: { name: 'My Pending Issues' },
          closed: { name: 'My Closed Issues' },
        },
      },
      all: {
        name: 'All Issues',
        isAllowed(me) {
          return !!me.issueManagerTypes.length;
        },
        children: {
          '/': { name: 'All Issues' },
          open: { name: 'All Pending Issues' },
          closed: { name: 'All Closed Issues' },
        },
      },
      new: { name: 'New Issue' },
      settings: { name: 'Settings', adGroup: IT_DEPARTMENT },
    },
  },
  messaging: {
    name: 'Messaging',
    adGroup: 'CSR',
    icon: () => <FontAwesomeIcon icon={faMessage} />,
    children: {
      'sire-confirmations': { name: 'Sire Confirmations' },
    },
  },
  semen: {
    name: 'Semen Inventory',
    icon: () => <FontAwesomeIcon icon={faVial} />,
    featureFlag: 'nucleus_2023-09-28_semen_pull',
  },
  'flash-reports': {
    name: 'Flash Reports',
    icon: () => <FontAwesomeIcon icon={faFileExcel} />,
    children: {
      'mto-kpis-by-lab': { name: 'MTO KPIs by Lab' },
      'mto-kpis-by-pwp': { name: 'MTO KPIs by PwP' },
    },
  },
  resource: {
    name: 'Resources',
    icon: () => <FontAwesomeIcon icon={faDatabase} />,
    children: {
      shipment: { name: 'Shipments' },
      equipment: {
        name: 'Equipments',
        adGroup: IT_DEPARTMENT,
      },
      fertilization: { name: 'Fertilizations' },
      'telemetry-events': {
        name: 'Telemetry Events',
        adGroup: IT_DEPARTMENT,
      },
    },
  },
};

type BreadcrumbRoute = {
  name: string;
  icon?: Icon;
  adGroup?: string;
  featureFlag?: string;
};
function makeBreadcrumb(
  parent: string,
  part: string,
  item: NucleusRouteDef,
): [string, BreadcrumbRoute][] {
  const path = `${parent}/${part}`;
  const entries = item.children
    ? Object.entries(item.children).flatMap(([part, item]) =>
        makeBreadcrumb(path, part, item),
      )
    : [];
  return [
    [
      path,
      {
        name: item.name,
        icon: item.icon,
        adGroup: item.adGroup,
        featureFlag: item.featureFlag,
      },
    ],
    ...entries,
  ];
}
const breadcrumbs = Object.fromEntries<BreadcrumbRoute>([
  ['/', root],
  ...Object.entries(routes).flatMap(([part, item]) =>
    makeBreadcrumb('', part, item),
  ),
]);

function getMenuItemFeatureFlag(item: NucleusRouteDef): string[] {
  const childrenFlags = item.children
    ? Object.values(item.children).map(getMenuItemFeatureFlag).flat(1)
    : [];
  return item.featureFlag
    ? [item.featureFlag, ...childrenFlags]
    : childrenFlags;
}
const menuFlags = Object.values(routes)
  .map((item) => getMenuItemFeatureFlag(item))
  .flat(1);

function RefreshButton() {
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const bus = useBus();
  const handleClick = useCallback(async () => {
    setLoading(true);

    try {
      await toast.promise(
        async () => {
          // clear cache and refetch active queries
          await client.resetStore();
          // tell active grids to refresh data
          bus.emit(REFRESH_GRID_EVENT);
        },
        {
          pending: 'Refreshing all the data.',
          success: 'Refreshed the data.',
          error: 'Encountered an error while refreshing the data.',
        },
      );
    } finally {
      setLoading(false);
    }
  }, [bus, client]);
  return (
    <Button
      aria-label="Refresh"
      loading={loading}
      icon={PrimeIcons.SYNC}
      rounded
      text
      onClick={handleClick}
      disabled={loading}
      style={{ marginLeft: 'auto' }}
    />
  );
}

export default function App() {
  const location = useLocation();
  useEffect(() => {
    document.body.setAttribute('data-path', location.pathname);
  }, [location.pathname]);

  const ancestors = useMemo(() => {
    const parts =
      location.pathname === '/' ? [''] : location.pathname.split('/');
    return parts
      .map((part, i, parts) =>
        part.length ? parts.slice(0, i + 1).join('/') : '/',
      )
      .map((path) => ({ path, ...breadcrumbs[path] }));
  }, [location.pathname]);

  useEffect(() => {
    const title = ancestors
      .filter((route) => route.path !== '/')
      .map((route) => route.name)
      .reverse()
      .filter(Boolean)
      .join(' - ');

    document.title =
      (title ? `${title} - ` : title) + 'Nucleus - Trans Ova Genetics';
  });

  const model = useMemo(
    () =>
      ancestors
        .filter((item) => item.path !== '/')
        .map((item) => ({
          label: item.name,
          icon: item.icon,
          url: item.path,
        })),
    [ancestors],
  );

  const meRes = useQuery(ME);
  const me = meRes.data?.me;

  const features = useQuery(CHECK_FEATURES, {
    variables: { features: menuFlags },
    skip: !menuFlags.length,
  });

  const handleFilter = useMemo(() => {
    return (item: NucleusRouteDef) => {
      const isInItDept = me?.groups.includes(IT_DEPARTMENT);
      const hasAdGroup = item.adGroup
        ? me?.groups.includes(item.adGroup)
        : true;
      const hasFlag = item.featureFlag
        ? features.data?.checkFeatures.includes(item.featureFlag)
        : true;

      const isAllowed = item.isAllowed ? me && item.isAllowed(me) : true;

      return !!((isInItDept || (hasAdGroup && hasFlag)) && isAllowed);
    };
  }, [features.data?.checkFeatures, me]);

  const menu = useMemo(
    () =>
      [
        [
          '/',
          {
            name: root.name,
            icon: root.icon,
          } satisfies RouteDef,
        ],
        ...Object.entries(routes),
      ] as [string, NucleusRouteDef][],
    [],
  );

  if (!me?.adUserId) {
    return (
      <div className="spinner-background">
        <div className="spinner">
          <Logo />
        </div>
      </div>
    );
  }

  return (
    <div className="app">
      <div className="app__menu">
        <Sidemenu menu={menu} filter={handleFilter} />
      </div>

      <div className="app__top">
        <BreadCrumb
          model={model}
          home={{
            label: root.name,
            icon: root.icon,
            url: '/',
          }}
        />

        <RefreshButton />
      </div>

      <main>
        <ToastContainer position="bottom-left" theme="colored" />
        <Outlet />
      </main>
    </div>
  );
}
