import { useApolloClient, useMutation } from '@apollo/client';
import {
  faArrowRightFromBracket,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '@images/logo_transova.svg?react';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import React, {
  createContext,
  CSSProperties,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';

import { LOGOUT } from '~/types.gql';

import './sidemenu.scss';

export type Icon = () => JSX.Element;

export type RouteDef = {
  name: string;
  icon?: Icon;
  hide?: true;
  children?: Record<string, RouteDef>;
};

const ActivePathContext = createContext<[string, (value: string) => void]>(
  [] as any,
);

function getPathParent(path: string) {
  return path.split('/').slice(0, -1).join('/') || '/';
}

function SidemenuLink({
  id,
  name,
  path,
  icon: Icon,
  level,
  isMenuOpen,
  setMenuOpen,
}: {
  id: string;
  name: string;
  path: string;
  level: number;
  icon: Icon | undefined;
  isMenuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const location = useLocation();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (!isMenuOpen) {
        e.preventDefault();
        setMenuOpen(true);
      }
    },
    [isMenuOpen, setMenuOpen],
  );

  return (
    <Link
      id={id}
      to={path}
      className={classNames('sidemenu__item', {
        'sidemenu__item--is-current': path === location.pathname,
      })}
      onClick={handleClick}
    >
      <span
        className={'sidemenu__item-icon'}
        style={{ '--menu-level': level } as CSSProperties}
      >
        {Icon ? <Icon /> : null}
      </span>

      <span className="sidemenu__item-text">{name}</span>
    </Link>
  );
}

function SidemenuButton({
  id,
  name,
  isActive,
  path,
  setActiveItemPath,
  level,
  icon: Icon,
  isMenuOpen,
  setMenuOpen,
}: {
  id: string;
  name: string;
  isActive: boolean;
  path: string;
  setActiveItemPath: (value: string) => void;
  level: number;
  icon: Icon | undefined;
  isMenuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (isMenuOpen) {
        setActiveItemPath(isActive ? getPathParent(path) : path);
      } else {
        e.stopPropagation();
        e.preventDefault();
        setMenuOpen(true);
        setActiveItemPath(path);
      }
    },
    [isMenuOpen, isActive, path, setActiveItemPath, setMenuOpen],
  );

  return (
    <button
      id={id}
      type="button"
      aria-label={`Toggle ${name}`}
      className={classNames('sidemenu__item', {
        'sidemenu__item--is-active': isActive,
      })}
      onClick={handleClick}
    >
      <span
        className={'sidemenu__item-icon'}
        style={{ '--menu-level': level } as CSSProperties}
      >
        {Icon ? <Icon /> : null}
      </span>

      <span className="sidemenu__item-text">{name}</span>

      <FontAwesomeIcon
        className="sidemenu__item-chevron"
        icon={isActive ? faChevronDown : faChevronRight}
      />
    </button>
  );
}

function BlankIcon() {
  return (
    <span style={{ height: '1em', width: '1em', display: 'inline-block' }} />
  );
}

function joinPath(a: string, b: string): string {
  const path = a + '/' + b;
  return '/' + path.split('/').filter(Boolean).join('/');
}

function ListItem<T extends RouteDef>({
  icon,
  path,
  item,
  level,
  isMenuOpen,
  setMenuOpen,
  filter,
}: {
  icon: Icon | undefined;
  path: string;
  item: T;
  level: number;
  isMenuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filter: (item: T) => boolean;
}) {
  const [activeItemPath, setActiveItemPath] = useContext(ActivePathContext);
  const isOpen = activeItemPath?.startsWith(path);

  const countChildren = useCallback((item: RouteDef): number => {
    const children = item.children
      ? Object.values(item.children).filter((r) => r.hide === undefined)
      : [];
    return (
      children.length +
      children.map((r) => countChildren(r)).reduce((acc, cur) => acc + cur, 0)
    );
  }, []);

  const childrenCount = useMemo(
    () => countChildren(item),
    [countChildren, item],
  );

  const passesFilter = useMemo(
    () => (filter ? filter(item) : true),
    [filter, item],
  );

  const isVisible = passesFilter && !item.hide;

  const children = useMemo(
    () => item.children && Object.entries(item.children),
    [item.children],
  );

  const shouldIncludeIcons = useMemo(
    () => children && children.some(([_part, r]) => r.icon),
    [children],
  );

  const id = useMemo(() => {
    const id =
      path === '/' ? 'sidemenu-root' : `sidemenu${path.split('/').join('-')}`;
    return children ? id : id + '-link';
  }, [children, path]);

  return isVisible ? (
    <li>
      {children ? (
        <SidemenuButton
          id={id}
          name={item.name}
          isActive={isOpen}
          path={path}
          setActiveItemPath={setActiveItemPath}
          icon={icon}
          level={level}
          isMenuOpen={isMenuOpen}
          setMenuOpen={setMenuOpen}
        />
      ) : (
        <SidemenuLink
          id={id}
          name={item.name}
          path={path}
          icon={icon}
          level={level}
          isMenuOpen={isMenuOpen}
          setMenuOpen={setMenuOpen}
        />
      )}

      <Tooltip target={`#${id}`} content={item.name} disabled={isMenuOpen} />

      {children ? (
        <ul
          className={classNames('sidemenu sidemenu--nested', {
            'sidemenu--is-wide': isMenuOpen && isOpen,
          })}
          style={{ '--children-count': childrenCount } as CSSProperties}
        >
          {children.map(([part, child]) => (
            <ListItem
              icon={shouldIncludeIcons ? child.icon || BlankIcon : child.icon}
              path={joinPath(path, part)}
              key={part}
              item={child as T}
              level={level + 1}
              isMenuOpen={isMenuOpen}
              setMenuOpen={setMenuOpen}
              filter={filter}
            />
          ))}
        </ul>
      ) : null}
    </li>
  ) : null;
}

function LogoutButton({ isMenuOpen }: { isMenuOpen: boolean }) {
  const client = useApolloClient();
  const [logout, res] = useMutation(LOGOUT);
  const handleClick = useCallback(async () => {
    await logout();
    await client.clearStore();

    const login = import.meta.env.VITE_LOGIN_URL;
    if (login) {
      // make user login and return
      const url = new URL(login);
      url.searchParams.set('returnUrl', window.location.href);
      window.location.assign(url.toString());
    } else {
      window.location.assign('https://www.transova.com/');
    }
  }, [client, logout]);
  return (
    <button
      className={classNames('logout-button', {
        'logout-button--is-wide': isMenuOpen,
      })}
      onClick={handleClick}
      disabled={res.loading}
    >
      <FontAwesomeIcon
        className="logout-button__icon"
        icon={faArrowRightFromBracket}
      />
      <span className="logout-button__text">Sign out</span>
    </button>
  );
}

const SIDEMENU_STATE = 'SIDEMENU_STATE';

export function Sidemenu<T extends RouteDef>({
  menu,
  filter,
}: {
  menu: [string, T][];
  filter: (item: T) => boolean;
}) {
  const location = useLocation();
  const [activeItemPath, setActiveItemPath] = useState(location.pathname);

  const [isMenuOpen, setMenuOpen] = useState(
    () => localStorage.getItem(SIDEMENU_STATE) === 'OPEN',
  );
  const toggleMenu = useCallback(
    () =>
      setMenuOpen((isOpen) => {
        const newMenuState = !isOpen;
        try {
          localStorage.setItem(
            SIDEMENU_STATE,
            newMenuState ? 'OPEN' : 'CLOSED',
          );
        } catch {
          // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#exceptions
        }
        return newMenuState;
      }),
    [],
  );

  return (
    <div
      className={classNames('sidemenu-container', {
        'sidemenu-container--is-wide': isMenuOpen,
      })}
    >
      <ul
        className={classNames('sidemenu', {
          'sidemenu--is-wide': isMenuOpen,
        })}
      >
        <li>
          <div className="sidemenu__toggle">
            <div className="sidemenu__toggle-button">
              <Button
                type="button"
                icon={PrimeIcons.BARS}
                onClick={toggleMenu}
                text
              />
            </div>

            <div className="sidemenu__toggle-logo">
              <Logo />
            </div>
          </div>
        </li>

        <ActivePathContext.Provider value={[activeItemPath, setActiveItemPath]}>
          {menu.map(([part, item]) => (
            <ListItem
              key={part}
              icon={item.icon}
              path={part === '/' ? '/' : `/${part}`}
              item={item}
              level={0}
              isMenuOpen={isMenuOpen}
              setMenuOpen={setMenuOpen}
              filter={filter}
            />
          ))}
        </ActivePathContext.Provider>
      </ul>

      <LogoutButton isMenuOpen={isMenuOpen} />
    </div>
  );
}
