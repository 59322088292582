import {
  createContext,
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

export type GridStore = { loaded?: number; total?: number };
const GridContext = createContext<
  [GridStore, Dispatch<SetStateAction<GridStore>>]
>([
  {},
  () => {
    console.error('Make sure to wrap your component in withGrid');
  },
]);
export function useGrid() {
  return useContext(GridContext);
}
export function withGrid<T>(Comp: React.FC<T>) {
  const C = memo(Comp) as any;
  return (props: T) => {
    const [store, setStore] = useState<GridStore>({});
    const value = useMemo(
      () =>
        [store, setStore] as [GridStore, Dispatch<SetStateAction<GridStore>>],
      [store],
    );
    return (
      <GridContext.Provider value={value}>
        <C {...props} />
      </GridContext.Provider>
    );
  };
}
