import type {
  ColumnApi,
  ColumnState,
  GridApi,
  ICombinedSimpleModel,
  SetFilterModel,
} from 'ag-grid-community';
import type { TextFilterModel } from 'ag-grid-enterprise';

export type SupportedFilter = SetFilterModel | TextFilterModel;
type CombinedFilter =
  | Omit<ICombinedSimpleModel<SupportedFilter>, 'condition1' | 'condition2'>
  | SupportedFilter;
export type FilterModel = Record<string, CombinedFilter>;
export type ColumnGridState = {
  filter?: CombinedFilter;
  sort?: 'asc' | 'desc';
};
export type GridState = Record<string, ColumnGridState | undefined>;
export type SortModel = Record<string, 'asc' | 'desc' | undefined>;

export function decodeGridState(
  cols: string[],
  encodedState: string | null,
): { filter: FilterModel; sort: SortModel } | void {
  if (!encodedState) {
    return;
  }

  let gridState: GridState | undefined;
  try {
    gridState = JSON.parse(encodedState) as GridState;
  } catch (e) {
    // ignore malformed json
  }
  if (!gridState) {
    return;
  }

  const filter = {} as FilterModel;
  const sort = {} as SortModel;

  for (const fieldName of cols) {
    const fieldState = gridState[fieldName];
    if (fieldState?.filter) {
      filter[fieldName] = fieldState.filter;
    }
    if (fieldState?.sort) {
      sort[fieldName] = fieldState.sort;
    }
  }

  return { filter, sort };
}

export function encodeGridState(
  cols: string[],
  filter: FilterModel,
  sort: Record<string, 'asc' | 'desc' | undefined>,
): string | void {
  const gridState = {} as GridState;

  for (const fieldName of cols) {
    if (filter[fieldName] || sort[fieldName]) {
      gridState[fieldName] = {
        filter: filter[fieldName],
        sort: sort[fieldName],
      };
    }
  }

  try {
    return JSON.stringify(gridState);
  } catch {
    //
  }
}

export function applyGridState(
  api: GridApi,
  columnApi: ColumnApi,
  encodedGridState: string | null,
): void {
  const columns = columnApi.getColumns();
  if (!columns) {
    return;
  }

  const cols = columnApi.getColumns()?.map((c) => c.getColDef().field) as
    | string[]
    | undefined;

  if (!cols) {
    return;
  }

  const gridState = decodeGridState(cols, encodedGridState);
  if (!gridState) {
    return;
  }

  api.setFilterModel(gridState.filter);

  const newColumnState = [] as ColumnState[];
  for (const col of columns) {
    const columnId = col.getColId();
    if (columnId && gridState.sort[columnId]) {
      newColumnState.push({
        colId: columnId,
        sort: gridState.sort[columnId],
      });
    }
  }
  if (newColumnState.length) {
    columnApi.applyColumnState({
      state: newColumnState,
      defaultState: { sort: null },
    });
  }
}
