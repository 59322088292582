import type {
  ValueFormatterFunc,
  ValueFormatterParams,
} from 'ag-grid-community';
import { format } from 'date-fns';

const DATE_FORMAT = 'M/d/y';
const DATE_TIME_FORMAT = 'M/d/y, h:mm a';
const TIME_24_FORMAT = 'HH:mm';

export function dateFormatter(value: string | null | undefined): string;
export function dateFormatter(value: ValueFormatterParams<any, string>): string;
export function dateFormatter(
  value: ValueFormatterParams<any, string> | string | null | undefined,
): string {
  const date = typeof value === 'object' ? value?.value : value;
  if (!date) {
    return '';
  }

  return format(new Date(date), DATE_FORMAT);
}

export const calendarDateFormatter: ValueFormatterFunc<any, string> = ({
  value,
}) => {
  if (!value) {
    return '';
  }

  const [year, month, day] = value.split('-').map((s) => parseInt(s));
  const monthIndex = month - 1;
  const date = new Date(year, monthIndex, day);
  return format(date, DATE_FORMAT);
};

export const timeFormatter: ValueFormatterFunc<any, string> = ({ value }) => {
  return value ? format(new Date(value), TIME_24_FORMAT) : '';
};

export const dateTimeFormatter: ValueFormatterFunc<any, string> = ({
  value,
}) => {
  if (!value) {
    return '';
  }

  const date = new Date(value);
  return format(date, DATE_TIME_FORMAT);
};

const percent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});
export function percentFormatter<T>({
  value,
}: ValueFormatterParams<T, number>) {
  if (typeof value !== 'number') {
    return '';
  }
  return percent.format(value);
}
