export default function NotFound() {
  return (
    <section
      style={{
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 'var(--size-4)',
        height: '100%',
      }}
    >
      <h2>Page Not Found</h2>
    </section>
  );
}
